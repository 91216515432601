<template>
  <div class="payment-page" :style="reservationStyle">
    <b-row class="reservation px-lg-5" >
      <b-col cols="12" class="mb-5">
        <h1 class="title">{{$t('Payment Recap')}}</h1>
        <div class="montserrat-medium rf-blue-color">{{$t('These are the costs related to your booking')}}</div>
      </b-col>
      <b-col cols="12" md="6">
        <div class="accommodation">
          <div class="room" v-if="isRoom">
            <div class="name">{{accommodation.type.title}}</div>
            <div class="address">{{accommodation.addressStreet}}</div>
          </div>
          <div class="apartment" v-else>
            <div class="name">{{accommodation.name}}</div>
            <div class="address">{{accommodation.addressStreet}}</div>
          </div>
        </div>
        <div class="reservation-info mt-4">
          <div class="info">
            <FontAwesomeIcon :icon="['fas', 'angle-right']"/> {{$t('Guest')}}
            <span class="content">
              <FontAwesomeIcon :icon="['fas', 'user']" class="mx-2" v-for="guest in numOfGuests" :key="guest"/>
            </span>
          </div>
          <div class="info">
            <FontAwesomeIcon :icon="['fas', 'angle-right']"/> {{$t('Period')}}
            <span class="content">
              {{reservation.startDate | extractMonthLocaleFromDate}} {{reservation.startDate | extractYearFromDate}} - {{reservation.expiringDate | extractMonthLocaleFromDate}} {{reservation.expiringDate | extractYearFromDate}}
            </span>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6" class="mt-3 mt-md-0">
        <VueperSlides :slide-ratio="0.5" :arrows="true" :bullets="false" infinite  :touchable="false">
          <VueperSlide v-for="(image, index) in accommodation.images" :key="index">
            <template v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']" #content>
              <div v-if="image.pathname.split('.')[1]!=='mp4'" class="vueperslide__content-wrapper">
                <b-img :src="image.pathname || null | getAsset" class="sliderCursor" fluid  @click="showModal(index)"  />
              </div>
              <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay muted loop playsinline class="sliderCursor" @click="showModal(index)">
                <source :src="image.pathname | getAsset" type="video/mp4">
              </video>
            </template>
          </VueperSlide>
        </VueperSlides>
      </b-col>
    </b-row>
    <b-row class="px-lg-5">
      <b-col class="pt-4">
        <div class="separator"></div>
      </b-col>
    </b-row>
    <b-row class="reservation-amounts mt-5 px-lg-5">
      <b-col cols="12" v-for="(value, index) in amounts" :key="index">
        <div v-if="value.title!=='Total'">
          <div class="amount">
            {{$t(value.title)}}
            <span class="price">
            {{value.price}} &euro;
              <!--            <FontAwesomeIcon class="cursor-pointer ml-1" :icon="['fas', 'chevron-down']" color="#74776D" @click="scrollToSection(`payments-${amount.scrollTo}`)"/>-->
            <FontAwesomeIcon v-if="!value.visible" class="cursor-pointer ml-1" :icon="['fas', 'chevron-down']" color="#74776D" @click="openDescription(value.title,amounts)"/>
            <FontAwesomeIcon v-if="value.visible" class="cursor-pointer ml-1" :icon="['fas', 'chevron-up']" color="#74776D" @click="closeDescription(value.title,amounts)"/>
          </span>
          </div>
          <div v-if="value.visible">
            <p v-for="descriptionParagraph in value.description" :key="descriptionParagraph" class="description">
              {{$t(descriptionParagraph)}}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-between px-lg-5">
      <b-col cols="auto" >
        <div class="reservation-total">
          <div class="total-label rf-blue-color text-uppercase">{{$t('Total')}}</div>
          <span class="montserrat-bold rf-blue-color">
            <span class="total-price">
              &euro; {{reservation.depositAmount+reservation.serviceFees}}
            </span>
          </span>
        </div>
      </b-col>
      <b-col cols="12" md="8" class="mt-3 mt-md-0">
        <div class="booking-concluded">
          <template>
            {{$t('Booking is concluded once the security deposit and the service fee are paid.')}}
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5 mb-3 px-lg-5">
      <b-col>
        <h1 class="title mb-0">{{$t('Payment Details')}}</h1>
      </b-col>
    </b-row>
    <!--    Now owner is always RF-->
    <!--    <div class="reservation-section" ref="payments-1" v-show="!ownerIsRoofinders && !isRoom">-->
    <!--      <h3 class="section-title px-lg-5 py-4">-->
    <!--        <template v-if="!isRoom">{{`1 - ${$t('Security deposit to be paid via bank transfer to the following account')}:`}}</template>-->
    <!--        <template v-else>{{`1 - ${$t('Booking is concluded once the security deposit is paid')}`}}</template>-->
    <!--      </h3>-->
    <!--    </div>-->
    <!--    <b-row class="px-lg-5 py-4" v-if="!ownerIsRoofinders && !isRoom">-->
    <!--      <b-col>-->
    <!--        <div class="owner-info" v-if="beneficiaryOwner">-->
    <!--          <p class="info">{{`${$t(`Beneficiary's name (owner's account)`)}: ${beneficiaryOwner.accountName}`}}</p>-->
    <!--          <p class="info">{{`${$t(`Beneficiary's address`)}: ${beneficiaryOwner.address}`}}</p>-->
    <!--          <p class="info">{{`${$t(`Account n. - IBAN`)}: ${beneficiaryOwner.iban}`}}</p>-->
    <!--          <p class="info">{{`${$t(`Bic/Swift code`)}: ${beneficiaryOwner.bic}`}}</p>-->
    <!--          <p class="info" v-if="beneficiaryOwner.bankName">{{`${$t(`Bank's name`)}: ${beneficiaryOwner.bankName}`}}</p>-->
    <!--          &lt;!&ndash; <p class="info">{{`${$t(`Bank's address`)}:`}}</p> &ndash;&gt;-->

    <!--        </div>-->
    <!--        <p class="payment-description">-->
    <!--          <span class="montserrat-bold">{{`${$t('Payment description')}:`}}</span>-->
    <!--          {{`SECURITY DEPOSIT - ${accommodation.buildingName ? accommodation.buildingName + ' - ' : ''} ${accommodation.name}`}}-->
    <!--        </p>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <ValidationObserver ref="paymentsSectionsValidator">
      <div class="reservation-section" ref="payments-2">
        <h3 class="section-title px-lg-5 py-4">{{`1 - ${$t('Security deposit and service Fees')}`}}</h3>
      </div>
      <b-row class="px-lg-5 py-4" v-if="!rentWithBankTransfer">
        <b-col cols="12">
          <h3 class="service-fees-title">{{`${$t('Pay with credit card')}...`}}</h3>
          <div class="service-fees-info">{{$t('Inserting credit card number, expiring date and security code')}}</div>
        </b-col>
        <b-col>

          <div class="owner-info separator mt-3" v-if="rfBankInfos">
            <div class="bank-transfer-title mb-4">{{`...${$t('or via bank transfer to following account')}:`}}</div>
            <div>
              <div>
                <p class="info" v-if="rfBankInfos.accountName">{{`${$t(`Beneficiary's name (owner's account)`)}: ${rfBankInfos.accountName}`}}</p>
                <p class="info" v-if="rfBankInfos.country">{{`${$t(`Beneficiary's country`)}: ${rfBankInfos.country}`}}</p>
                <p class="info" v-if="rfBankInfos.address">{{`${$t(`Beneficiary's address`)}: ${rfBankInfos.address}`}}</p>
              </div>
              <div class="mt-2">
                <p class="info" v-if="rfBankInfos.bankName">{{`${$t(`Bank's name`)}: ${rfBankInfos.bankName}`}}</p>
                <p class="info" v-if="rfBankInfos.bankAddress">{{`${$t(`Bank's address`)}: ${rfBankInfos.bankAddress}`}}</p>
                <p class="info" v-if="rfBankInfos.bankCountry">{{`${$t(`Bank's country`)}: ${rfBankInfos.bankCountry}`}}</p>
              </div>
              <div class="mt-2">
                <p class="info" v-if="rfBankInfos.iban">{{`${$t(`Account n. - IBAN`)}: ${rfBankInfos.iban}`}}</p>
                <p class="info" v-if="rfBankInfos.bic">{{`${$t(`Bic/Swift code`)}: ${rfBankInfos.bic}`}}</p>
              </div>
            </div>

            <p class="payment-description">
              <span class="montserrat-bold">{{`${$t('Payment description')}: `}}</span>
              <!--            <template v-if="!ownerIsRoofinders">-->
              <!--              <template v-if="!isRoom">{{`SERVICE FEES - ${accommodation.buildingName ? accommodation.buildingName + ' - ' : ''} ${accommodation.name}`}}</template>-->
              <!--              <template v-else>{{`SECURITY DEPOSIT - ${accommodation.buildingName ? accommodation.buildingName + ' - ' : ''} ${accommodation.name}`}}</template>-->
              <!--            </template>-->
              <template>{{`SECURITY DEPOSIT & SERVICE FEES - ${accommodation.buildingName ? accommodation.buildingName + ' - ' : ''} ${accommodation.name}`}}</template>
            </p>
          </div>
          <div class="monthly-rent-text">
            <p class="montserrat-medium rf-blue-color mb-1">{{$t('I choose to pay the security deposit and service fees with')}}</p>
          </div>
          <RFValidation rules="required" name="Deposit and service fee payment method">
            <RFSelect :top-label="$t('Deposit and service fees payment method')" v-model="paymentMethods.deposit" @input="paymentMethods.deposit = $event" :list="paymentTypes" :readonly="payWithCreditCard"/>
          </RFValidation>
        </b-col>
      </b-row>

      <b-row class="px-lg-5 py-4" v-else>
        <b-col cols="12">
          <div class="only-bank-transfer-monthly-rent-text">
            <p class="montserrat-medium rf-blue-color">{{$t('You are paying with bank transfer')}}</p>
          </div>
        </b-col>
        <b-col>

          <div class="owner-info separator" v-if="accommodationOwnerInfo">
            <div class="bank-transfer-title mb-3">{{`${$t('Pay security deposit to the following account')}:`}}</div>
            <div>
              <div>
                <p class="info" v-if="accommodationOwnerInfo.accountName">{{`${$t(`Beneficiary's name (owner's account)`)}: ${accommodationOwnerInfo.accountName}`}}</p>
                <p class="info" v-if="accommodationOwnerInfo.country">{{`${$t(`Beneficiary's country`)}: ${accommodationOwnerInfo.country}`}}</p>
                <p class="info" v-if="accommodationOwnerInfo.address">{{`${$t(`Beneficiary's address`)}: ${accommodationOwnerInfo.address}`}}</p>
              </div>
              <div class="mt-2">
                <p class="info" v-if="accommodationOwnerInfo.bankName">{{`${$t(`Bank's name`)}: ${accommodationOwnerInfo.bankName}`}}</p>
                <p class="info" v-if="accommodationOwnerInfo.bankAddress">{{`${$t(`Bank's address`)}: ${accommodationOwnerInfo.bankAddress}`}}</p>
                <p class="info" v-if="accommodationOwnerInfo.bankCountry">{{`${$t(`Bank's country`)}: ${accommodationOwnerInfo.bankCountry}`}}</p>
              </div>
              <div class="mt-2">
                <p class="info" v-if="accommodationOwnerInfo.iban">{{`${$t(`Account n. - IBAN`)}: ${accommodationOwnerInfo.iban}`}}</p>
                <p class="info" v-if="accommodationOwnerInfo.bic">{{`${$t(`Bic/Swift code`)}: ${accommodationOwnerInfo.bic}`}}</p>
              </div>
            </div>
          </div>

          <div class="owner-info separator" v-if="rfBankInfos">
            <div class="bank-transfer-title mb-3 mt-3">{{`${$t('Pay Service fee to the following bank account')}:`}}</div>
            <div>
              <div>
                <p class="info" v-if="rfBankInfos.accountName">{{`${$t(`Beneficiary's name (owner's account)`)}: ${rfBankInfos.accountName}`}}</p>
                <p class="info" v-if="rfBankInfos.country">{{`${$t(`Beneficiary's country`)}: ${rfBankInfos.country}`}}</p>
                <p class="info" v-if="rfBankInfos.address">{{`${$t(`Beneficiary's address`)}: ${rfBankInfos.address}`}}</p>
              </div>
              <div class="mt-2">
                <p class="info" v-if="rfBankInfos.bankName">{{`${$t(`Bank's name`)}: ${rfBankInfos.bankName}`}}</p>
                <p class="info" v-if="rfBankInfos.bankAddress">{{`${$t(`Bank's address`)}: ${rfBankInfos.bankAddress}`}}</p>
                <p class="info" v-if="rfBankInfos.bankCountry">{{`${$t(`Bank's country`)}: ${rfBankInfos.bankCountry}`}}</p>
              </div>
              <div class="mt-2">
                <p class="info" v-if="rfBankInfos.iban">{{`${$t(`Account n. - IBAN`)}: ${rfBankInfos.iban}`}}</p>
                <p class="info" v-if="rfBankInfos.bic">{{`${$t(`Bic/Swift code`)}: ${rfBankInfos.bic}`}}</p>
              </div>
            </div>
          </div>

          <div class="owner-info separator" v-if="rfBankInfos">
            <p class="payment-description">
              <span class="montserrat-bold">{{`${$t('Payment description')}: `}}</span>
              <!--            <template v-if="!ownerIsRoofinders">-->
              <!--              <template v-if="!isRoom">{{`SERVICE FEES - ${accommodation.buildingName ? accommodation.buildingName + ' - ' : ''} ${accommodation.name}`}}</template>-->
              <!--              <template v-else>{{`SECURITY DEPOSIT - ${accommodation.buildingName ? accommodation.buildingName + ' - ' : ''} ${accommodation.name}`}}</template>-->
              <!--            </template>-->
              <template>{{`SECURITY DEPOSIT & SERVICE FEES - ${accommodation.buildingName ? accommodation.buildingName + ' - ' : ''} ${accommodation.name}`}}</template>
            </p>
          </div>
        </b-col>
      </b-row>

      <div class="reservation-section" ref="payments-3">
        <h3 class="section-title px-lg-5 py-4">
          {{`2 - ${$t(`Monthly rent`)}`}}
        </h3>
      </div>
      <div class="monthly-rent px-lg-5 mt-4 pb-4">
        <div class="monthly-rent-text montserrat-medium">
          <p class="montserrat-medium">{{$t(monthlyRentPrincipalDescription)}}</p>
        </div>
        <b-row class="monthly-rent-descriptions mt-3">
          <b-col cols="12" v-for="(value, index) in monthlyRentDescriptions" :key="index">
            <div v-if="value.bankTransferCondition">
              <div class="monthly-rent-description-text montserrat-medium rf-blue-color">
                <p class="montserrat-medium">{{$t(value.title)}}
                  <span>
                    <FontAwesomeIcon v-if="!value.visible" class="cursor-pointer ml-1" :icon="['fas', 'chevron-down']" color="#74776D" @click="openDescription(value.title,monthlyRentDescriptions)"/>
                    <FontAwesomeIcon v-if="value.visible" class="cursor-pointer ml-1" :icon="['fas', 'chevron-up']" color="#74776D" @click="closeDescription(value.title,monthlyRentDescriptions)"/>
                  </span>
                </p>
              </div>
              <div v-if="value.visible">
                <p v-for="descriptionParagraph in value.description" :key="descriptionParagraph" class="description">
                  {{$t(descriptionParagraph)}}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="monthly-rent-text" v-if="!rentWithBankTransfer">
          <p class="montserrat-medium rf-blue-color mt-3 mb-1">{{$t('I choose to pay monthly rent with')}}</p>
        </div>
        <RFValidation rules="required" name="Monthly rent payment method" v-if="!rentWithBankTransfer">
          <RFSelect :top-label="$t('Monthly rent payment method')" v-model="paymentMethods.monthly" @input="paymentMethods.monthly = $event" :list="monthlyPaymentTypes" :readonly="payWithCreditCard"/>
        </RFValidation>
      </div>
    </ValidationObserver>
    <div class="reservation-section" ref="payments-4">
      <h3 class="section-title px-lg-5 py-4" v-if="ownerIsRoofinders">{{`3 - ${$t('Submit payment')}`}}</h3>
    </div>
    <b-row class="px-lg-5 py-4">
      <b-col cols="12">
        <div class="monthly-rent-text">
          <p class="montserrat-medium rf-blue-color mb-1" v-if="!payWithCreditCard">{{$t('To submit the payment, press the "NEXT" button')}}</p>
          <p class="montserrat-medium rf-blue-color mb-1" v-else>{{$t('To finish the payment, press the "INSERT CREDIT CARD" button')}}</p>
        </div>
      </b-col>
      <b-col>
        <RFButton class="mt-4" label="Next" background="#24425B" color="#fff" @on-click="submitPayment" v-if="!loading && !payWithCreditCard"/>
        <a :href="`${this.creditCardUrl}`" v-if="payWithCreditCard">
          <RFButton class="mt-4" label="Insert credit card" background="#24425B" color="#fff" v-if="!loading"/>
        </a>
        <div class="monthly-rent mt-4">
          <div class="monthly-rent-text">
            <div class="montserrat-medium rf-blue-color mt-4">
              {{$t('Please remember to send proof of payment to the following address:')}}
              <a class="mail rf-blue-color" href="mailto:ciao@roofinders.com">ciao@roofinders.com</a>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="px-lg-5 py-2">
      <b-col cols="12">
        <p class="paragraph montserrat-medium rf-blue-color">
          {{$t(`In case you have doubts, don't hesitate to`)}}
          <a href="https://wa.me/393518897372" target="_blank" class="montserrat-bold rf-blue-color">
            {{$t('contact us')}}
            <FontAwesomeIcon :color="isRoom ? '#E26F5A' : '#67AEA6'" :icon="['fab', 'whatsapp']"/>
          </a>
        </p>
      </b-col>
    </b-row>
    <BankTransferModal />
    <CarouselModal />
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFSelect from '@/components/forms/RFSelect'
import RFValidation from '@/components/forms/RFValidation'
import BankTransferModal from '@/components/modals/BankTransferModal'
import {ownerService, paymentService} from '@/services'
import {recipientTypes} from '@/enums'
import {store} from '@/stores'
import {mapState} from 'vuex'
import {isAdmin} from '@/helpers/functions'
import CarouselModal from '@/components/modals/CarouselModal.vue'

export default {
  name: 'PaymentPage',
  components: {
    RFValidation,
    RFButton,
    RFLoader,
    RFSelect,
    BankTransferModal,
    CarouselModal,
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.userStore.loggedIn) {
      const userRoles = store.state.userStore.user.roles
      const reservation = store.state.profileStore.reservation

      if (isAdmin(userRoles)) {
        next({ name: 'admin' })
      } else if (!isAdmin(userRoles) && !reservation) {
        next({ name: 'your-roof' })
      } else {
        next()
      }
    } else {
      next({ name: 'homepage' })
    }
  },
  data() {
    return {
      recipientTypes,
      rfBankInfos: null,
      accommodationOwnerInfo: null,
      loading: false,
      amounts: [],
      monthlyRentDescriptions: [],
      monthlyPaymentTypes:[
        {
          value: 'CCA',
          label: 'Credit Card (automatic)'
        },
        {
          value: 'CCM',
          label: 'Credit Card (manual)'
        },
        {
          value: 'BT',
          label: 'Bank Transfer'
        },
      ],
      paymentTypes: [
        {
          value: 'CC',
          label: 'Credit Card'
        },
        {
          value: 'BT',
          label: 'Bank Transfer'
        },
      ],
      paymentMethods: {
        deposit: null,
        monthly: null
      },
      payWithCreditCard: false,
      creditCardUrl: null,
      serviceFeesDescription: null,
      monthlyRentPrincipalDescription: "Monthly rent is paid within the first 5 days of the month, once you enter the unit (after contract starting date)"
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation
    }),
    accommodation() {
      return this.reservation.apartment || this.reservation.room
    },
    isRoom() {
      return this.reservation.room
    },
    reservationStyle() {
      return {
        '--color': !this.isRoom ? '#67AEA6' : '#E26F5A'
      }
    },
    numOfGuests() {
      return this.reservation.guests.length + 1
    },
    beneficiaryOwner() {
      return (this.isRoom)
        ? this.accommodation.owner
        : this.accommodation.owners.length
          ? this.accommodation.owners[0]
          : null
    },
    ownerIsRoofinders() {
      // return this.accommodation.paymentRecipient === this.recipientTypes.RF
      //<!--    Now owner is always RF-->
      return true
    },
    isCategoryVisible(){
      return this.amounts['Service Fees'].visible
    },
    rentWithBankTransfer(){
      return (this.reservation.apartment && this.reservation.apartment.bankTransferMonthlyPayments) || (this.reservation.room && this.reservation.room.bankTransferMonthlyPayments)
    }
  },
  async beforeMount() {

    // this.rfBankInfos = !this.ownerIsRoofinders
    //                   ? await ownerService.getRoofindersInfos()
    //                   : this.beneficiaryOwner
    //<!--    Now owner is always RF-->
    this.rfBankInfos = await ownerService.getRoofindersInfos()

    if(this.rentWithBankTransfer){
      this.paymentMethods.deposit='BT'
      this.paymentMethods.monthly='BT'
      //Set accommodation's owner info
      this.accommodationOwnerInfo = this.beneficiaryOwner == null ? this.rfBankInfos : this.beneficiaryOwner
    }

    if(this.isRoom){
      this.serviceFeesDescription = "There is a one-time service fee to be paid, which includes support and assistance throughout the entire stay, maintenance costs (so if there is any problem in the house, we fix it for you!). This amount considers VAT charge equal to 22%"
    }
    else{
      this.serviceFeesDescription = "There is a one-time service fee to be paid equal to one month rent, which includes contract registration costs, support during the entire stay, and monthly maintenance check ups which will be performed in the unit"
    }
    //Adding data to amounts
    this.amounts.push({
      title: 'Security deposit',
      price: this.reservation.depositAmount,
      description : [
        "The amount relative to the security deposit shall be paid in advance. This amount shall be restituted once you leave the apartment and we have checked that it is  in the same conditions as initially delivered"
      ],
      visible : false})

    this.amounts.push({
      title: 'Service Fees',
      price: this.reservation.serviceFees,
      description : [
        this.serviceFeesDescription
      ],
      visible: false })

    this.amounts.push({
      title: 'Monthly rent',
      price: this.reservation.monthlyAmount,
      description: [
        "Price amount description",
        "This price does not include the costs of electricity and gas which vary upon consumption and will be communicated once the bills are issued by the specific providers",
        "This amount is due within the first 5 working days of the month via credit card or bank transfer"
      ],
      visible: false })

    this.amounts.push({
      title: 'Total',
      price: this.reservation.monthlyAmount,
      description: [
        "The security deposit and service fee need to be paid in order to finalize the booking. Rent will be paid once you enter the unit!"
      ],
      visible: false
    })
  },
  async mounted() {
    //Adding data to monthlyRentDescriptions
    this.monthlyRentDescriptions.push({
      title: 'If you choose to pay with credit card (automatic)',
      description : [
        "The credit card used for the payment of the security deposit and the service fee will be automatically charged every 1st of the month with the amount relative to the monthly rent, for the whole duration of the stay.",
        "The card can be changed after the first payment is done.",
        "This option is valid only if the deposit is paid with credit card."
      ],
      visible : false,
      bankTransferCondition: !this.rentWithBankTransfer})
    this.monthlyRentDescriptions.push({
      title: 'If you choose to pay with credit card (manual)',
      description : [
        "CCManualPaymentPrincipalDescription",
        "The card can be changed in each payment.",
        "This option is valid only if the deposit is paid with credit card."
      ],
      visible : false,
      bankTransferCondition: !this.rentWithBankTransfer
    })
    this.monthlyRentDescriptions.push({
      title: 'If you choose to pay with bank transfer',
      description : [
        "The amount needs to be transferred within 5 working days of the month to the same account where the security deposit was paid."
      ],
      visible : false,
      bankTransferCondition: !this.rentWithBankTransfer})
    this.monthlyRentDescriptions.push({
      title: 'You are paying with bank transfer',
      description : [
        "The amount needs to be transferred within 5 working days of the month to the same account where the security deposit was paid."
      ],
      visible : false,
      bankTransferCondition: this.rentWithBankTransfer})
  },
  beforeDestroy() {
    this.$store.commit('profileStore/setReservation', null)
  },
  methods: {
    openDescription(title,array){
      array.forEach(item=>{
        if(item.title === title){
          item.visible = true
        }
        else{
          item.visible = false
        }
      })
    },
    closeDescription(title,array){
      let found = false
      let i
      for(i=0;i<array.length && !found;i++){
        if(array[i].title===title){
          array[i].visible = false
          found = true
        }
      }
    },
    showModal(index) {
      this.$store.commit('carouselStore/setIndexSlide', index)
      this.$store.commit('carouselStore/setImagesList', this.accommodation.images)
      this.$root.$emit('bv::show::modal', 'carousel-modal')
    },
    scrollToSection(section) {
      const offset = this.$refs[section].offsetTop

      window.scroll({
        top: offset - 86,
        behavior: 'smooth',
      })
    },
    openModal() {
      this.$root.$emit('bv::show::modal', 'bank-transfer-modal')
    },
    async submitPayment(){
      this.donePressed = true
      this.loading = true
      const isValid = await this.$refs.paymentsSectionsValidator.validate()

      //Check if the payment method is specified for both sections
      if (!isValid){
        this.$toasted.error(this.$i18n.t(`You must complete the payment method for all sections`))
        this.loading = false
      }
      //Check if was selected deposit with BT and monthly payment with CC. In that case, an error message is shown (invalid combination)
      else if(this.paymentMethods.deposit==='BT' && (this.paymentMethods.monthly==='CCA' || this.paymentMethods.monthly==='CCM')) {
        this.$toasted.error(this.$i18n.t(`Invalid combination: You can only make monthly payments with credit card if the deposit is paid with credit card too`))
      }
      else{
        await this.makePayment()
      }
      this.loading = false
    },
    async makePayment(){
      //Check the payment case

      //Case 4(Deposit and monthly payments with BT)
      if(this.paymentMethods.deposit==='BT' && this.paymentMethods.monthly==='BT'){
        const confirmed = await paymentService.confirmBankTransferPayment(this.reservation.id)

        if (confirmed) {
          this.$toasted.success(this.$i18n.t(`Thank you! We'll wait for your bank transfer payment`))
          this.$router.push({ name: 'your-roof' })
        }
        else {
          this.$toasted.error(this.$i18n.t(`An error occurred. Please, try again.`))
        }
      }
      //Otherwise, go to the page to insert credit card
      else{
        // //Define url
        if(this.paymentMethods.deposit==='CC'){
          //Case 1
          //Automatic payments
          if(this.paymentMethods.monthly==='CCA'){
            this.creditCardUrl=`${this.$route.name}/credit-card-deposit-and-monthly`
          }
          //Manual payments
          else if(this.paymentMethods.monthly==='CCM'){
            this.creditCardUrl=`${this.$route.name}/credit-card-deposit-and-monthly-manual`
          }
          //Case 2
          else{
            this.creditCardUrl=`${this.$route.name}/credit-card-deposit-bt-monthly`
          }
        }

        this.payWithCreditCard = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-page {
  padding: 30px 0;

  .video-wrapper {
    /* Will not allow the video to overflow the
    container */
    overflow: hidden;

    /* Centering the container's content vertically
    and horizontally */
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    /** Simulationg background-size: cover */
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
  .reservation {
    .accommodation {
      .room,
      .apartment {
        .name,
        .address {
          @include font-style($montserrat-bold, 'bold', $font-20);
          text-transform: uppercase;
          color: var(--color);
        }
        .address {
          @include font-style($montserrat-light, 'light', $font-20);
        }
      }
    }
    .reservation-info {
      .info {
        @include font-style($montserrat-bold, 'bold', $font-14);
        color: var(--color);

        .content {
          @include font-style($montserrat, 'medium', $font-14);
          color: $dark-blue;
        }
      }
    }
  }
  .reservation-amounts {
    .amount {
      @include font-style($montserrat-light, 'light', $font-16);
      color: $dark-blue;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .price {
        @include font-style($montserrat-bold, 'bold', $font-16);
      }
    }
    .description {
      @include font-style($montserrat-light, 'light', $font-12);
      color: $dark-blue;
      margin-top: 15px;
      padding-bottom: 30px;
      border-bottom: 1px solid $dark-gray;
    }
  }
  .monthly-rent-descriptions {
    .description {
      @include font-style($montserrat-light, 'light', $font-12);
      color: var(--color);
      margin-top: 15px;
      padding-bottom: 30px;
      border-bottom: 1px solid $dark-gray;
    }
  }
  .reservation-total {
    .total-price {
      font-size: $font-24;
    }
  }
  .booking-concluded {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: var(--color);
    text-transform: uppercase;
  }

  .reservation-section {
    background-color: var(--color);

    .section-title {
      position: relative;
      @include font-style($montserrat-bold, 'bold', $font-18);
      color: $white;
      padding: 0;
      z-index: 5;
      text-transform: uppercase;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100%;
        background-color: var(--color);
        z-index: -1;
      }
      &:after {
        left: 100%;
      }
      &:before {
        left: -100%;
      }
    }
  }
  .owner-info {
    .info {
      @include font-style($montserrat, 'medium', $font-14);
      color: $dark-blue;
    }
    &.separator {
      padding-top: 20px;

      .bank-transfer-title {
        @include font-style($montserrat-bold, 'bold', $font-18);
        color: var(--color);
        text-transform: uppercase;
      }
      .only-bank-transfer-title {
        @include font-style($montserrat-bold, 'bold', $font-14);
        color: var(--color);
        text-transform: uppercase;
      }
    }
    .info {
      margin: 0;
    }
  }
  .payment-description {
    color: $dark-blue;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .service-fees-title {
    @include font-style($montserrat-bold, 'bold', $font-18);
    color: var(--color);
    text-transform: uppercase;
  }
  .service-fees-info {
    @include font-style($montserrat, 'medium', $font-14);
    color: $dark-blue;
  }
  .credit-card-form {
    .card-box {
      margin: 0 5px;
      padding-top: 10px;
      border: 1px solid $dark-blue;
    }
  }
  .no-service-fees {
    @include font-style($montserrat, 'medium', $font-14);
    color: var(--color);
  }
  .monthly-rent-text {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: var(--color);

    .mail {
      color: var(--color);
    }
  }
  .monthly-rent-description-text {
    @include font-style($montserrat-bold, 'bold', $font-14);
  }
  .only-bank-transfer-monthly-rent-text {
    @include font-style($montserrat-bold, 'bold', $font-18);
    color: var(--color);

    .mail {
      color: var(--color);
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .payment-page {
    .reservation-amounts {
      .amount {
        width: 50%;
      }
    }
    .reservation-section {
      .section-title {
        &:before {
          display: none;
        }
      }
    }
  }
}
</style>

