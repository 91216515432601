<template>
  <RFModal :title="$t('Bank Transfer')" :modal-id="modalId" @send="confirmBankTransferPayment" :loading="loading"
  ok-label="Ok, I understood">
  <div class="paragraph-container">
    <div class="paragraph">
      {{$t('In order to finalize your booking, please proceed with the bank transfer within 48hs. And remember to send proof of payment to')}}
      <a class="email" href="mailto:ciao@roofinders.com">ciao@roofinders.com</a>
      {{$t('once the transaction is concluded')}}
    </div>
  </div>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import {paymentService} from '@/services'
import {mapState} from 'vuex'

export default {
  name: 'BankTransferModal',
  components: {
    RFModal,
  },
  data() {
    return {
      modalId: 'bank-transfer-modal',
      loading: false,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
    })
  },
  methods: {
    async confirmBankTransferPayment() {
      this.loading = true
      const confirmed = await paymentService.confirmBankTransferPayment(this.reservation.id)

      if (confirmed) {
        this.$toasted.success(this.$i18n.t(`Thank you! We'll wait for your bank transfer payment`))
        this.$router.push({ name: 'your-roof' })
      } else {
        this.$toasted.error(this.$i18n.t(`An error occurred. Please, try again.`))
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;

  .paragraph {
    @include font-style($montserrat-bold, 'medium', $font-16);
    color: $dark-blue;
    text-transform: uppercase;
    width: 60%;

    .email {
      color: $green;
    }
  }
}
</style>
